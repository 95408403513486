import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../utils/guards/auth.guard';
import { NewsComponent } from './news/news.component';
import { AppConstants } from '../utils/constants'
import { NewsDetailComponent } from './news/news-detail/news-detail.component';
import { OfficesComponent } from './offices/offices.component';
import { ChangePasswordAdminComponent } from './change-password-admin/change-password-admin.component';
import { SettingsComponent } from './settings/settings.component';
import { ToolsComponent } from './tools/tools.component';

const routes: Routes = [
  { path: '', redirectTo: 'news', pathMatch: 'full' },
  { path: 'news', component: NewsComponent, data: { roles: [AppConstants.USER_TYPE_SUPER_ADMIN], breadcrumb: 'Comunicazioni' }, canActivate: [AuthGuard] },
  { path: 'news/new', component: NewsDetailComponent, data: { roles: [AppConstants.USER_TYPE_SUPER_ADMIN], breadcrumb: 'Nuova Comuncazione' }, canActivate: [AuthGuard] },
  { path: 'news/:id', component: NewsDetailComponent, data: { roles: [AppConstants.USER_TYPE_SUPER_ADMIN], breadcrumb: 'Dettaglio Comuncazione' }, canActivate: [AuthGuard] },
  { path: 'offices', component: OfficesComponent, data: { roles: [AppConstants.USER_TYPE_SUPER_ADMIN], breadcrumb: 'Uffici' }, canActivate: [AuthGuard] },
  { path: 'change_password', component: ChangePasswordAdminComponent, data: { roles: [AppConstants.USER_TYPE_SUPER_ADMIN], breadcrumb: 'Cambio Password' }, canActivate: [AuthGuard] },
  { path: 'settings', component: SettingsComponent, data: { roles: [AppConstants.USER_TYPE_SUPER_ADMIN], breadcrumb: 'Impostazioni' }, canActivate: [AuthGuard] },
  { path: 'tools', component: ToolsComponent, data: { roles: [AppConstants.USER_TYPE_SUPER_ADMIN], breadcrumb: 'Strumenti' }, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdministratorRoutingModule { }
